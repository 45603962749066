<template>
  <div
    class="flex min-h-[90vh] flex-col overflow-hidden pb-navbar-mobile pl-container-mobile-sm pt-hero-top-ident md:px-container-desktop-base md:pb-navbar lg:flex-row lg:gap-container-mobile-base"
  >
    <!-- TEXT -->
    <div
      class="w-full py-8 max-lg:order-2 max-lg:pr-container-mobile-sm max-sm:hyphens-auto md:py-20 lg:w-1/2 xl:w-2/5"
    >
      <!-- Title -->
      <h1
        class="relative z-10 mb-5 max-w-title whitespace-pre-wrap text-typo-5xl text-gold-300 md:mb-8 md:text-typo-6xl lg:-mr-56 xl:-mr-96"
        v-html="$useRemoveHtmlTags(hero.heading)"
      />

      <!-- Text -->
      <div
        class="text-link max-w-text text-typo-base font-medium md:text-typo-lg"
        v-html="hero.lead_text"
      />

      <UiButton
        v-if="hero.link"
        :to="hero.link"
        class="mt-8 uppercase"
        variant="regular"
        type="button"
      >
        {{ hero.link_text }}</UiButton
      >
    </div>

    <!-- IMAGE -->
    <div
      class="relative w-full grow max-lg:order-1 max-lg:h-[40svh] lg:ml-0 lg:w-1/2"
    >
      <div class="absolute inset-0">
        <AppImage
          :src="hero.image"
          class="size-full object-cover"
          :style="`object-position: ${hero.image?.focus_css};`"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { Form } from 'vee-validate'

const props = defineProps({
  hero: { type: Object, required: true },
})

const state = ref('idle')

const config = useRuntimeConfig()

const inputField = computed(() => {
  if (
    !props.hero.form_landingpage ||
    !props.hero.form_landingpage.fields.length
  ) {
    return null
  }
  return props.hero.form_landingpage.fields[0]
})

async function onSubmit(fields) {
  state.value = 'pending'
  try {
    await $fetch(
      `${config.public.BACKEND_URL}/!/forms/${props.hero.form_landingpage.handle}`,
      {
        method: 'POST',
        body: fields,
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
        },
      }
    )
    state.value = 'success'
  } catch (error) {
    state.value = 'error'
    console.error(error)
  }
}
</script>

<gql component>
  heading
  lead_text
  image {
    ...Asset
  }
  link_text
  link
</gql>
